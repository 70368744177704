<script setup lang="ts">
const { fullWidth } = withDefaults(
  defineProps<{
    fullWidth?: boolean
    applyPadding?: boolean
  }>(),
  {
    fullWidth: false,
    // Apply px-4
    applyPadding: true,
  },
)
</script>

<template>
  <section
    class="relative mx-auto w-full"
    :class="{ 'max-w-7xl': !fullWidth, 'px-4': applyPadding }"
  >
    <slot />
  </section>
</template>
